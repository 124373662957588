<template>    
  <div class="background_basic">          
    <v-card class="ma-3">
      <v-toolbar dense flat color="white">
          <v-toolbar-title class="grey--text text--darken-3">
            <v-icon class="mr-2">mdi-database-search</v-icon>출입인원 현황조회
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn color="blue" text @click="initialize()" class="font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">refresh</v-icon>초기화
          </v-btn>
          <v-btn color="blue" text @click="ExcelExpert('출입인원현황조회')" class = "ml-2 font-weight-bold text-subtitle-1">
            <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
          </v-btn>
        <v-spacer></v-spacer>
                
      </v-toolbar>
      <v-divider></v-divider>    

      <v-card flat class="d-flex">       
        <v-tabs v-model="model">
          <v-tab center-active class="ml-6 font-weight-bold" @click="DailyView()" >일별통계</v-tab>
          <v-tab center-active class="font-weight-bold" @click="MonthlyView()" >월별통계</v-tab>
        </v-tabs>
      </v-card>
      <v-divider></v-divider> 

      
      <v-card flat class="d-flex">      
        <v-card flat class="ml-5 mt-0 mb-0" style="width:200px; height:50px;">
          <v-menu ref="menu" v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y 
          > 
            <template v-slot:activator="{ on, attrs }">
              <v-text-field  v-model="date" label="년월"  v-on="on" v-bind="attrs" ></v-text-field>
            </template>            
            <v-date-picker v-model="date" type="month" no-title scrollable locale="ko" min="1900-01-01">
              <v-spacer></v-spacer>
              <v-btn text color="blue darken-1" @click="$refs.menu.save(date);" class="text-subtitle-2 font-weight-bold">확인</v-btn>
              <v-btn text color="grey darken-2" @click="menu = false" class="text-subtitle-2 font-weight-bold">취소</v-btn>
              
            </v-date-picker>
          </v-menu>          
        </v-card>        
        <v-card class="ml-3 mb-0" flat style="max-width:150px;">
          <v-text-field persistent-placeholder class="ml-2 mb-n3" readonly label="위치" v-model="cNowLocationNm" append-icon="mdi-close" @click:append="onNowLocationDel" @click="selectMap" placeholder="위치를 선택하세요."  style="max-width:250px;"></v-text-field>
        </v-card>
        <v-btn text color="black" class="ml-2 mt-3 text-subtitle-1" @click="Search()">
          <v-icon>search</v-icon>검색
        </v-btn>  
      </v-card>
    
      <v-divider></v-divider>    
      <v-data-table         
        :items-per-page="-1" 
        fixed-header
        height="calc(100vh - 300px)"
        :headers="headers" 
        :items="RecordsDay"        
        v-if="table1"
      >
        <template v-slot:item.cZone="{ item }">
          <div label v-if="item.iLev == 0" class="font-weight-bold">{{item.cZone}}</div>
          <div v-else-if="item.iLev == 1" class="font-weight-bold">&nbsp;&nbsp;- {{item.cZone}}</div>
          <div v-else-if="item.iLev == 2" @click="setIsGroup(item)"  style="cursor:pointer">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{getIsGroup(item)}} {{item.cZone}}</div>
          <div v-else-if="item.iLev == 3" class="caption grey--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{item.cZone}}</div>          
        </template>

        <template v-slot:item.day01="{ item }">
          <div style="cursor:pointer"  @click="showInfo('01', item)" v-if="item.iLev in [0,1]" class="font-weight-bold " >{{item.day01}}</div>          
          <div style="cursor:pointer"  @click="showInfo('01', item)" v-else-if="item.iLev == 2" >{{item.day01}}</div>          
          <div style="cursor:pointer"  @click="showInfo('01', item)" v-else class="caption grey--text">{{item.day01}}</div>          
        </template>
        <template v-slot:item.day02="{ item }">
          <div style="cursor:pointer"  @click="showInfo('02', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day02}}</div>          
          <div style="cursor:pointer"  @click="showInfo('02', item)" v-else-if="item.iLev == 2" >{{item.day02}}</div>          
          <div style="cursor:pointer"  @click="showInfo('02', item)" v-else class="caption grey--text">{{item.day02}}</div>          
        </template>
        <template v-slot:item.day03="{ item }">
          <div style="cursor:pointer"  @click="showInfo('03', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day03}}</div>          
          <div style="cursor:pointer"  @click="showInfo('03', item)" v-else-if="item.iLev == 2" >{{item.day03}}</div>          
          <div style="cursor:pointer"  @click="showInfo('03', item)" v-else class="caption grey--text">{{item.day03}}</div>          
        </template>
        <template v-slot:item.day04="{ item }">
          <div style="cursor:pointer"  @click="showInfo('04', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day04}}</div>          
          <div style="cursor:pointer"  @click="showInfo('04', item)" v-else-if="item.iLev == 2" >{{item.day04}}</div>          
          <div style="cursor:pointer"  @click="showInfo('04', item)" v-else class="caption grey--text">{{item.day04}}</div>          
        </template>
        <template v-slot:item.day05="{ item }">
          <div style="cursor:pointer"  @click="showInfo('05', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day05}}</div>          
          <div style="cursor:pointer"  @click="showInfo('05', item)" v-else-if="item.iLev == 2" >{{item.day05}}</div>          
          <div style="cursor:pointer"  @click="showInfo('05', item)" v-else class="caption grey--text">{{item.day05}}</div>          
        </template>
        <template v-slot:item.day06="{ item }">
          <div style="cursor:pointer"  @click="showInfo('06', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day06}}</div>          
          <div style="cursor:pointer"  @click="showInfo('06', item)" v-else-if="item.iLev == 2" >{{item.day06}}</div>          
          <div style="cursor:pointer"  @click="showInfo('06', item)" v-else class="caption grey--text">{{item.day06}}</div>          
        </template>        
        <template v-slot:item.day07="{ item }">
          <div style="cursor:pointer"  @click="showInfo('07', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day07}}</div>          
          <div style="cursor:pointer"  @click="showInfo('07', item)" v-else-if="item.iLev == 2" >{{item.day07}}</div>          
          <div style="cursor:pointer"  @click="showInfo('07', item)" v-else class="caption grey--text">{{item.day07}}</div>          
        </template>        
        <template v-slot:item.day08="{ item }">
          <div style="cursor:pointer"  @click="showInfo('08', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day08}}</div>          
          <div style="cursor:pointer"  @click="showInfo('08', item)" v-else-if="item.iLev == 2" >{{item.day08}}</div>          
          <div style="cursor:pointer"  @click="showInfo('08', item)" v-else class="caption grey--text">{{item.day08}}</div>          
        </template>        
        <template v-slot:item.day09="{ item }">
          <div style="cursor:pointer"  @click="showInfo('09', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day09}}</div>          
          <div style="cursor:pointer"  @click="showInfo('09', item)" v-else-if="item.iLev == 2" >{{item.day09}}</div>          
          <div style="cursor:pointer"  @click="showInfo('09', item)" v-else class="caption grey--text">{{item.day09}}</div>          
        </template>        
        <template v-slot:item.day10="{ item }">
          <div style="cursor:pointer"  @click="showInfo('10', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day10}}</div>          
          <div style="cursor:pointer"  @click="showInfo('10', item)" v-else-if="item.iLev == 2" >{{item.day10}}</div>          
          <div style="cursor:pointer"  @click="showInfo('10', item)" v-else class="caption grey--text">{{item.day10}}</div>          
        </template>        

        <template v-slot:item.day11="{ item }">
          <div style="cursor:pointer"  @click="showInfo('11', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day11}}</div>          
          <div style="cursor:pointer"  @click="showInfo('11', item)" v-else-if="item.iLev == 2" >{{item.day11}}</div>          
          <div style="cursor:pointer"  @click="showInfo('11', item)" v-else class="caption grey--text">{{item.day11}}</div>          
        </template>
        <template v-slot:item.day12="{ item }">
          <div style="cursor:pointer"  @click="showInfo('12', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day12}}</div>          
          <div style="cursor:pointer"  @click="showInfo('12', item)" v-else-if="item.iLev == 2" >{{item.day12}}</div>          
          <div style="cursor:pointer"  @click="showInfo('12', item)" v-else class="caption grey--text">{{item.day12}}</div>          
        </template>
        <template v-slot:item.day13="{ item }">
          <div style="cursor:pointer"  @click="showInfo('13', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day13}}</div>          
          <div style="cursor:pointer"  @click="showInfo('13', item)" v-else-if="item.iLev == 2" >{{item.day13}}</div>          
          <div style="cursor:pointer"  @click="showInfo('13', item)" v-else class="caption grey--text">{{item.day13}}</div>          
        </template>
        <template v-slot:item.day14="{ item }">
          <div style="cursor:pointer"  @click="showInfo('14', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day14}}</div>          
          <div style="cursor:pointer"  @click="showInfo('14', item)" v-else-if="item.iLev == 2" >{{item.day14}}</div>          
          <div style="cursor:pointer"  @click="showInfo('14', item)" v-else class="caption grey--text">{{item.day14}}</div>          
        </template>
        <template v-slot:item.day15="{ item }">
          <div style="cursor:pointer"  @click="showInfo('15', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day15}}</div>          
          <div style="cursor:pointer"  @click="showInfo('15', item)" v-else-if="item.iLev == 2" >{{item.day15}}</div>          
          <div style="cursor:pointer"  @click="showInfo('15', item)" v-else class="caption grey--text">{{item.day15}}</div>          
        </template>
        <template v-slot:item.day16="{ item }">
          <div style="cursor:pointer"  @click="showInfo('16', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day16}}</div>          
          <div style="cursor:pointer"  @click="showInfo('16', item)" v-else-if="item.iLev == 2" >{{item.day16}}</div>          
          <div style="cursor:pointer"  @click="showInfo('16', item)" v-else class="caption grey--text">{{item.day16}}</div>          
        </template>        
        <template v-slot:item.day17="{ item }">
          <div style="cursor:pointer"  @click="showInfo('17', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day17}}</div>          
          <div style="cursor:pointer"  @click="showInfo('17', item)" v-else-if="item.iLev == 2" >{{item.day17}}</div>          
          <div style="cursor:pointer"  @click="showInfo('17', item)" v-else class="caption grey--text">{{item.day17}}</div>          
        </template>        
        <template v-slot:item.day18="{ item }">
          <div style="cursor:pointer"  @click="showInfo('18', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day18}}</div>          
          <div style="cursor:pointer"  @click="showInfo('18', item)" v-else-if="item.iLev == 2" >{{item.day18}}</div>          
          <div style="cursor:pointer"  @click="showInfo('18', item)" v-else class="caption grey--text">{{item.day18}}</div>          
        </template>        
        <template v-slot:item.day19="{ item }">
          <div style="cursor:pointer"  @click="showInfo('19', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day19}}</div>          
          <div style="cursor:pointer"  @click="showInfo('19', item)" v-else-if="item.iLev == 2" >{{item.day19}}</div>          
          <div style="cursor:pointer"  @click="showInfo('19', item)" v-else class="caption grey--text">{{item.day19}}</div>          
        </template>        
        <template v-slot:item.day20="{ item }">
          <div style="cursor:pointer"  @click="showInfo('20', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day20}}</div>          
          <div style="cursor:pointer"  @click="showInfo('20', item)" v-else-if="item.iLev == 2" >{{item.day20}}</div>          
          <div style="cursor:pointer"  @click="showInfo('20', item)" v-else class="caption grey--text">{{item.day20}}</div>          
        </template>     

        <template v-slot:item.day21="{ item }">
          <div style="cursor:pointer"  @click="showInfo('21', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day21}}</div>          
          <div style="cursor:pointer"  @click="showInfo('21', item)" v-else-if="item.iLev == 2" >{{item.day21}}</div>          
          <div style="cursor:pointer"  @click="showInfo('21', item)" v-else class="caption grey--text">{{item.day21}}</div>          
        </template>
        <template v-slot:item.day22="{ item }">
          <div style="cursor:pointer"  @click="showInfo('22', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day22}}</div>          
          <div style="cursor:pointer"  @click="showInfo('22', item)" v-else-if="item.iLev == 2" >{{item.day22}}</div>          
          <div style="cursor:pointer"  @click="showInfo('22', item)" v-else class="caption grey--text">{{item.day22}}</div>          
        </template>
        <template v-slot:item.day23="{ item }">
          <div style="cursor:pointer"  @click="showInfo('23', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day23}}</div>          
          <div style="cursor:pointer"  @click="showInfo('23', item)" v-else-if="item.iLev == 2" >{{item.day23}}</div>          
          <div style="cursor:pointer"  @click="showInfo('23', item)" v-else class="caption grey--text">{{item.day23}}</div>          
        </template>
        <template v-slot:item.day24="{ item }">
          <div style="cursor:pointer"  @click="showInfo('24', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day24}}</div>          
          <div style="cursor:pointer"  @click="showInfo('24', item)" v-else-if="item.iLev == 2" >{{item.day24}}</div>          
          <div style="cursor:pointer"  @click="showInfo('24', item)" v-else class="caption grey--text">{{item.day24}}</div>          
        </template>
        <template v-slot:item.day25="{ item }">
          <div style="cursor:pointer"  @click="showInfo('25', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day25}}</div>          
          <div style="cursor:pointer"  @click="showInfo('25', item)" v-else-if="item.iLev == 2" >{{item.day25}}</div>          
          <div style="cursor:pointer"  @click="showInfo('25', item)" v-else class="caption grey--text">{{item.day25}}</div>          
        </template>
        <template v-slot:item.day26="{ item }">
          <div style="cursor:pointer"  @click="showInfo('26', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day26}}</div>          
          <div style="cursor:pointer"  @click="showInfo('26', item)" v-else-if="item.iLev == 2" >{{item.day26}}</div>          
          <div style="cursor:pointer"  @click="showInfo('26', item)" v-else class="caption grey--text">{{item.day26}}</div>          
        </template>        
        <template v-slot:item.day27="{ item }">
          <div style="cursor:pointer"  @click="showInfo('27', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day27}}</div>          
          <div style="cursor:pointer"  @click="showInfo('27', item)" v-else-if="item.iLev == 2" >{{item.day27}}</div>          
          <div style="cursor:pointer"  @click="showInfo('27', item)" v-else class="caption grey--text">{{item.day27}}</div>          
        </template>        
        <template v-slot:item.day28="{ item }">
          <div style="cursor:pointer"  @click="showInfo('28', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day28}}</div>          
          <div style="cursor:pointer"  @click="showInfo('28', item)" v-else-if="item.iLev == 2" >{{item.day28}}</div>          
          <div style="cursor:pointer"  @click="showInfo('28', item)" v-else class="caption grey--text">{{item.day28}}</div>          
        </template>        
        <template v-slot:item.day29="{ item }">
          <div style="cursor:pointer"  @click="showInfo('29', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day29}}</div>          
          <div style="cursor:pointer"  @click="showInfo('29', item)" v-else-if="item.iLev == 2" >{{item.day29}}</div>          
          <div style="cursor:pointer"  @click="showInfo('29', item)" v-else class="caption grey--text">{{item.day29}}</div>          
        </template>        
        <template v-slot:item.day30="{ item }">
          <div style="cursor:pointer"  @click="showInfo('30', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day30}}</div>          
          <div style="cursor:pointer"  @click="showInfo('30', item)" v-else-if="item.iLev == 2" >{{item.day30}}</div>          
          <div style="cursor:pointer"  @click="showInfo('30', item)" v-else class="caption grey--text">{{item.day30}}</div>          
        </template>        
        <template v-slot:item.day31="{ item }">
          <div style="cursor:pointer"  @click="showInfo('31', item)" v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.day31}}</div>          
          <div style="cursor:pointer"  @click="showInfo('31', item)" v-else-if="item.iLev == 2" >{{item.day31}}</div>          
          <div style="cursor:pointer"  @click="showInfo('31', item)" v-else class="caption grey--text">{{item.day31}}</div>          
        </template>        

        <template v-slot:item.daysum="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.daysum}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.daysum}}</div>          
          <div v-else class="caption grey--text">{{item.daysum}}</div> 
        </template>
      </v-data-table>

      <!-- 월별통계 테이블 -->
      <v-data-table         
        :items-per-page="-1"
        fixed-header
        height="calc(100vh - 300px)" 
        :headers="headers2" 
        :items="RecordsMonth"        
        v-if="table2"
      >
        <template v-slot:item.cZone="{ item }">
          <div label v-if="item.iLev == 0" class="font-weight-bold">{{item.cZone}}</div>
          <div v-else-if="item.iLev == 1" class="font-weight-bold">&nbsp;&nbsp;- {{item.cZone}}</div>          
          <div v-else-if="item.iLev == 2" @click="setIsMonthGroup(item)"  style="cursor:pointer">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{getIsMonthGroup(item)}} {{item.cZone}}</div>
          <div v-else-if="item.iLev == 3" class="caption grey--text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {{item.cZone}}</div>          
        </template>

        <template v-slot:item.month01="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month01}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month01}}</div>          
          <div v-else class="caption grey--text">{{item.month01}}</div>          
        </template>
        <template v-slot:item.month02="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month02}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month02}}</div>          
          <div v-else class="caption grey--text">{{item.month02}}</div>          
        </template>
        <template v-slot:item.month03="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month03}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month03}}</div>          
          <div v-else class="caption grey--text">{{item.month03}}</div>          
        </template>
        <template v-slot:item.month04="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month04}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month04}}</div>          
          <div v-else class="caption grey--text">{{item.month04}}</div>          
        </template>
        <template v-slot:item.month05="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month05}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month05}}</div>          
          <div v-else class="caption grey--text">{{item.month05}}</div>          
        </template>
        <template v-slot:item.month06="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month06}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month06}}</div>          
          <div v-else class="caption grey--text">{{item.month06}}</div>          
        </template>
        <template v-slot:item.month07="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month07}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month07}}</div>          
          <div v-else class="caption grey--text">{{item.month07}}</div>          
        </template>
        <template v-slot:item.month08="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month08}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month08}}</div>          
          <div v-else class="caption grey--text">{{item.month08}}</div>          
        </template>
        <template v-slot:item.month09="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month09}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month09}}</div>          
          <div v-else class="caption grey--text">{{item.month09}}</div>          
        </template>
        <template v-slot:item.month10="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month10}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month10}}</div>          
          <div v-else class="caption grey--text">{{item.month10}}</div>          
        </template>
        <template v-slot:item.month11="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month11}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month11}}</div>          
          <div v-else class="caption grey--text">{{item.month11}}</div>          
        </template>
        <template v-slot:item.month12="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.month12}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.month12}}</div>          
          <div v-else class="caption grey--text">{{item.month12}}</div>          
        </template>


        <template v-slot:item.cmonth="{ item }">
          <div v-if="item.iLev in [0,1]" class="font-weight-bold ">{{item.cmonth}}</div>          
          <div v-else-if="item.iLev == 2" >{{item.cmonth}}</div>          
          <div v-else class="caption grey--text">{{item.cmonth}}</div> 
        </template>
      </v-data-table>     
    </v-card>
    <GetSelMapOne ref="GetSelMapOneRef" @onGetMap="onGetMap" />   

    <v-dialog v-model="isShowInfo" max-width="500px">
      <v-card>
        <v-toolbar dense flat color="white">
          
          인원정보         
          <v-spacer></v-spacer> 
          <v-btn color="black darken-1" icon @click="isShowInfo = false"
            ><v-icon>mdi-close</v-icon></v-btn>
          
        </v-toolbar>
        <v-divider></v-divider>   
        <div style="background-color:#00000000"> 
          <v-data-table             
            fixed-header             
            :headers="zUserheaders"             
              height="70vh"     
            :items="zUserLog">            
            <template v-slot:no-data>
              <p>No data available.</p>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import BasicInfo from "@/BasicInfo.js";
import XLSX from 'xlsx';
import Util from "../Util.js";
import EventBus from '@/eventBus.js';
import GetSelMapOne from "@/components/GetSelMapOne.vue";
import { mapState } from "vuex";

export default {
   components: {
     GetSelMapOne,
   },
 
  data: () => ({
    cGroup : "ALL",
    isGroupALL : false,
    date: new Date().toISOString().substr(0, 7), //(2021-09)의 date의 0부터 7자리의 숫자를 데이터형태로 가져옴    
    table1: false,
    table2: false,
    menu: false,
    menu2: false,
    model : 0,
    cMapGroupId : "00",
    cBuildingId : "00",
    cMapId : "00",
    cNowLocationNm: "ALL",    
    isShowInfo : false,
    zUserLog : [],    
    zUserheaders: [            
      { class: "font-weight-bold subtitle-2", text: "업체명", value: "cCorpNm", align: "center"},      
      { class: "font-weight-bold subtitle-2", text: "이름", value: "cUserNm", align: "center"},            
    ],
    headers: [
      { class: "font-weight-bold subtitle-3", sortable: false, text: '구역', value: 'cZone', width: 200},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '1일', value: 'day01'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '2일', value: 'day02'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '3일', value: 'day03'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '4일', value: 'day04'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '5일', value: 'day05'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '6일', value: 'day06'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '7일', value: 'day07'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '8일', value: 'day08'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '9일', value: 'day09'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '10일', value: 'day10'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '11일', value: 'day11'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '12일', value: 'day12'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '13일', value: 'day13'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '14일', value: 'day14'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '15일', value: 'day15'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '16일', value: 'day16'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '17일', value: 'day17'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '18일', value: 'day18'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '19일', value: 'day19'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '20일', value: 'day20'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '21일', value: 'day21'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '22일', value: 'day22'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '23일', value: 'day23'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '24일', value: 'day24'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '25일', value: 'day25'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '26일', value: 'day26'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '27일', value: 'day27'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '28일', value: 'day28'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '29일', value: 'day29'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '30일', value: 'day30'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '31일', value: 'day31'},
      { class: "font-weight-bold subtitle-1", align: "center", sortable: false, text: '합계', value: 'daysum'},
    ],
    
    headers2: [
      { class: "font-weight-bold subtitle-3", sortable: false, text: '구역', value: 'cZone', width: 200},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '1월', value: 'month01'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '2월', value: 'month02'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '3월', value: 'month03'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '4월', value: 'month04'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '5월', value: 'month05'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '6월', value: 'month06'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '7월', value: 'month07'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '8월', value: 'month08'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '9월', value: 'month09'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '10월', value: 'month10'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '11월', value: 'month11'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '12월', value: 'month12'},
      { class: "font-weight-bold subtitle-2", align: "center", sortable: false, text: '합계', value: 'monthsum'},
    ],

    Records: [],
    RecordsDay: [],
    cNowGroupBuildingId : "",

    Records2: [],     
    RecordsMonth: [],         
    cNowMonthGroupBuildingId : "",

    cNowDate : "",

  }),

  computed: {
    ...mapState(["zGroupAll",]),    
  },
   
  created () {
    this.initialize()
  },

  methods: {
    initialize() {
      this.cNowLocation= "";
      this.cNowLocationNm = "ALL";  
      this.date = new Date().toISOString().substr(0, 7), //(2021-09)의 date의 0부터 7자리의 숫자를 데이터형태로 가져옴
      this.date2 = new Date().toISOString().substr(0, 4), 
      this.DailyView();
      this.model = 0;
    },
    
    showInfo(day, item){      
      this.isShowInfo = true;
      this.zUserLog = [];
      
      var posData = {
        Date: this.cNowDate + "-" + day,
        cMapGroupId : item.cMapGroupId,
        cBuildingId : item.cBuildingId,
        cMapId : item.cMapId,   
        cNowSiteId: this.$store.state.UserInfo.NowSiteId,
      };

      axios
      .post(BasicInfo.UIL_API + "GetEnterUserLogDetail", posData, {
        headers: {
          Authorization: this.$store.state.UserInfo.Token
        }
      })
      .then(res => {
        if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
        if (res.data.ResultCd === "00") {  
          this.zUserLog = res.data.Result;           
          
        } else {
          EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);            
        }
      })
      .catch(err => {
        EventBus.$emit("onShowMsg",true, ""+err);                   
      });
    },
    selectMap () {
      this.$refs.GetSelMapOneRef.show(); 
    },

    onGetMap(select) {
      this.cMapGroupId = select.cMapGroupId;  
      this.cBuildingId = select.cBuildingId;  
      this.cMapId = select.cMapId;  
      this.cNowLocationNm = select.cMapNm;  
    },

    onNowLocationDel () {
      this.cMapGroupId = "00";
      this.cBuildingId = "00";      
      this.cMapId = "00";
      this.cNowLocationNm = "ALL"; 
    },

    saveYear(year) {      
        this.$refs.menu2.save(year);        
        this.$refs.picker.activePicker = 'YEAR';        
        this.menu2 = false;
    },

    setIsGroup(item) {
      if (this.cNowGroupBuildingId == "") {
        this.cNowGroupBuildingId = item.cMapGroupId + item.cBuildingId;
      } else {
        if (this.cNowGroupBuildingId == item.cMapGroupId + item.cBuildingId) {
          this.cNowGroupBuildingId = "";
        } else {
          this.cNowGroupBuildingId = item.cMapGroupId + item.cBuildingId;
        }        

      }      
      this.getRecordDay();
    },
    getIsGroup(item) {
      if(this.cNowGroupBuildingId == item.cMapGroupId + item.cBuildingId) {
        return "-";
      } else {
        return "+";
      }
    },
    getRecordDay() {
      this.RecordsDay = [];
      for (var i in this.Records) {        
        if (this.Records[i].cMapId != "00") {          
          if (this.cNowGroupBuildingId == this.Records[i].cMapGroupId + this.Records[i].cBuildingId) {
            this.RecordsDay.push(this.Records[i]);  
          }
        } else {
          
          this.RecordsDay.push(this.Records[i]);
        }        
      }
    },
 
    getdaily() { 
      this.Records = [];
      this.RecordsDay = [];
      this.cNowGroupBuildingId = "";

      this.cNowDate = this.date;
      var posData = {
        Date: this.date,
        cMapGroupId : this.cMapGroupId,
        cBuildingId : this.cBuildingId,
        cMapId : this.cMapId,   
        cNowSiteId: this.$store.state.UserInfo.NowSiteId,
      };     
        axios
        .post(BasicInfo.UIL_API + "GetEnterUserLog", posData, {
          headers: {
            Authorization: this.$store.state.UserInfo.Token
          }
        })
        .then(res => {
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {            
            for(var i = 0; i < res.data.Result2.length; i++){
              var a = {
                cZone : res.data.Result2[i].cMapNm,
                cIdx : res.data.Result2[i].cIdx,
                cMapGroupId : res.data.Result2[i].cMapGroupId,
                cBuildingId : res.data.Result2[i].cBuildingId,
                cMapId : res.data.Result2[i].cMapId,
                iLev : res.data.Result2[i].iLev,
                day01 : 0,
                day02 : 0,
                day03 : 0,
                day04 : 0,
                day05 : 0,
                day06 : 0,
                day07 : 0,
                day08 : 0,
                day09 : 0,
                day10 : 0,
                day11 : 0,
                day12 : 0,
                day13 : 0,
                day14 : 0,
                day15 : 0,
                day16 : 0,
                day17 : 0,
                day18 : 0,
                day19 : 0,
                day20 : 0,
                day21 : 0,
                day22 : 0,
                day23 : 0,
                day24 : 0,
                day25 : 0,
                day26 : 0,
                day27 : 0,
                day28 : 0,
                day29 : 0,
                day30 : 0,
                day31 : 0,
                daysum: 0,
              }
              this.Records.push(a);
            }             
                    
            if (res.data.Result1 != null) {              
              for(var j = 0; j < res.data.Result1.length; j++){
                for(var z = 0; z < this.Records.length; z++){
                  if (res.data.Result1[j].cIdx == this.Records[z].cIdx) {                  
                    if (res.data.Result1[j].cday == '01') { this.Records[z].day01 = this.Records[z].day01 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '02') { this.Records[z].day02 = this.Records[z].day02 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '03') { this.Records[z].day03 = this.Records[z].day03 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '04') { this.Records[z].day04 = this.Records[z].day04 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '05') { this.Records[z].day05 = this.Records[z].day05 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '06') { this.Records[z].day06 = this.Records[z].day06 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '07') { this.Records[z].day07 = this.Records[z].day07 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '08') { this.Records[z].day08 = this.Records[z].day08 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '09') { this.Records[z].day09 = this.Records[z].day09 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '10') { this.Records[z].day10 = this.Records[z].day10 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '11') { this.Records[z].day11 = this.Records[z].day11 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '12') { this.Records[z].day12 = this.Records[z].day12 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '13') { this.Records[z].day13 = this.Records[z].day13 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '14') { this.Records[z].day14 = this.Records[z].day14 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '15') { this.Records[z].day15 = this.Records[z].day15 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '16') { this.Records[z].day16 = this.Records[z].day16 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '17') { this.Records[z].day17 = this.Records[z].day17 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '18') { this.Records[z].day18 = this.Records[z].day18 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '19') { this.Records[z].day19 = this.Records[z].day19 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '20') { this.Records[z].day20 = this.Records[z].day20 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '21') { this.Records[z].day21 = this.Records[z].day21 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '22') { this.Records[z].day22 = this.Records[z].day22 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '23') { this.Records[z].day23 = this.Records[z].day23 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '24') { this.Records[z].day24 = this.Records[z].day24 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '25') { this.Records[z].day25 = this.Records[z].day25 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '26') { this.Records[z].day26 = this.Records[z].day26 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '27') { this.Records[z].day27 = this.Records[z].day27 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '28') { this.Records[z].day28 = this.Records[z].day28 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '29') { this.Records[z].day29 = this.Records[z].day29 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '30') { this.Records[z].day30 = this.Records[z].day30 + parseInt(res.data.Result1[j].counts);}
                    if (res.data.Result1[j].cday == '31') { this.Records[z].day31 = this.Records[z].day31 + parseInt(res.data.Result1[j].counts);}
                    this.Records[z].daysum +=  parseFloat(res.data.Result1[j].counts);
                    break;
                  }
                }       
              }    
            }

            this.getRecordDay();
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);            
          }
        })
        .catch(err => {
          EventBus.$emit("onShowMsg",true, ""+err);                   
        });
    },


    setIsMonthGroup(item) {
      if (this.cNowMonthGroupBuildingId == "") {
        this.cNowMonthGroupBuildingId = item.cMapGroupId + item.cBuildingId;
      } else {
        if (this.cNowMonthGroupBuildingId == item.cMapGroupId + item.cBuildingId) {
          this.cNowMonthGroupBuildingId = "";
        } else {
          this.cNowMonthGroupBuildingId = item.cMapGroupId + item.cBuildingId;
        }        
      }      
      this.getRecordMonth();
    },
    getIsMonthGroup(item) {
      if(this.cNowMonthGroupBuildingId == item.cMapGroupId + item.cBuildingId) {
        return "-";
      } else {
        return "+";
      }
    },
    getRecordMonth() {      
      this.RecordsMonth = [];
      for (var i in this.Records2) {
        if (this.Records2[i].cMapId != "00") {          
          if (this.cNowMonthGroupBuildingId == this.Records2[i].cMapGroupId + this.Records2[i].cBuildingId) {
            this.RecordsMonth.push(this.Records2[i]);  
          }
        } else {                
          this.RecordsMonth.push(this.Records2[i]);
        }        
      }
    },

    getmonthly() {      
      this.Records2 = [];
      this.RecordsMonth = [];
      this.cNowMonthGroupBuildingId = "";
      var posData = {
        Date: this.date.substr(0, 4),
        cMapGroupId : this.cMapGroupId,
        cBuildingId : this.cBuildingId,
        cMapId : this.cMapId, 
        cNowSiteId: this.$store.state.UserInfo.NowSiteId,
      };

      
      axios
      .post(BasicInfo.UIL_API + "GetEnterUserLog1", posData, {
        headers: {
          Authorization: this.$store.state.UserInfo.Token
        }
      })
      .then(res => {
        if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
        
        if (res.data.ResultCd === "00") {                        
          for(var i = 0; i < res.data.Result2.length; i++){
            var a = {
              cZone : res.data.Result2[i].cMapNm,
              cIdx : res.data.Result2[i].cIdx,
              iLev : res.data.Result2[i].iLev,                
              cMapGroupId : res.data.Result2[i].cMapGroupId,
              cBuildingId : res.data.Result2[i].cBuildingId,
              cMapId : res.data.Result2[i].cMapId,                    
              month01 : 0,
              month02 : 0,
              month03 : 0,
              month04 : 0,
              month05 : 0,
              month06 : 0,
              month07 : 0,
              month08 : 0,
              month09 : 0,
              month10 : 0,
              month11 : 0,
              month12 : 0,
              monthsum : 0,
            }
            this.Records2.push(a);
          }             
                  
          for(var j = 0; j < res.data.Result1.length; j++){
            for(var z = 0; z < this.Records2.length; z++){
              if (res.data.Result1[j].cIdx == this.Records2[z].cIdx) {                  
                if (res.data.Result1[j].cmonth == '01') { this.Records2[z].month01 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '02') { this.Records2[z].month02 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '03') { this.Records2[z].month03 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '04') { this.Records2[z].month04 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '05') { this.Records2[z].month05 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '06') { this.Records2[z].month06 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '07') { this.Records2[z].month07 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '08') { this.Records2[z].month08 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '09') { this.Records2[z].month09 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '10') { this.Records2[z].month10 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '11') { this.Records2[z].month11 = res.data.Result1[j].counts;}
                if (res.data.Result1[j].cmonth == '12') { this.Records2[z].month12 = res.data.Result1[j].counts;}
                this.Records2[z].monthsum += parseFloat(res.data.Result1[j].counts);
                break;
              }
            }       
          }    

          this.getRecordMonth();
        } else {
          EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);            
        }
      })
      .catch(err => {
        EventBus.$emit("onShowMsg",true, ""+err);                   
      });
    },

    //검색클릭시,일별통계시 일별검색 
    Search() {
      if (this.table1 == true) {
        this.getdaily();
      } else {
        this.getmonthly();
      }
    },

    //일별통계클릭시, 테이블(table1)으로 변경
    DailyView() {
      this.table1= true;
      this.table2= false;
      this.getdaily();
    },

    //월별통계클릭시, 테이블(table2)로 변경
    MonthlyView() {
      this.table2= true;
      this.table1= false;
      this.getmonthly();
      
    },

    //엑셀 다운로드
    ExcelExpert(name){
      if (this.table1 == true) {
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
      } else {
      var r2 = Util.GetExcel(this.Records2, this.headers2);
      var animalWS2 = XLSX.utils.json_to_sheet(r2)
      var wb2 = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb2, animalWS2, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb2, name + "-" + Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
      }
    },
  },
  
}

</script>
